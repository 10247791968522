import { graphql } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import Layout from "../components/layout"
import parse, { domToReact } from "html-react-parser"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      legalDisclaimers(locales: $locale) {
        title
        headline
        content {
          html
        }
        id
        slug
      }
    }
  }
`

const legalDisclaimer = ({ t, data }) => {
  const options = {
    replace: ({ attribs, name, children }) => {
      if (name === "a") {
        return (
          <a
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            href={attribs.href}
          >
            {domToReact(children, options)}
          </a>
        )
      }
    },
  }
  return (
    <Layout>
      <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
        <div className="text-center inline-block bg-white">
          <span className="text-2xl">&lt;</span>
          <span className="tracking-title-3 text-base ml-3 mr-2 uppercase">
          {t("footer_title_legal_disclaimer")}
          </span>
          <span className="text-2xl">&gt;</span>
        </div>
      </div>
      <div className="mx-3 lg:mx-7">
        {data.gcms.legalDisclaimers.map((item, i) => (
          <div key={i} className="grid grid-col-1 md:grid-cols-5 gap-4">
            <div className="col-span-1">
              <p className="font-futurastd_heavy uppercase text-lg">
                {item.title}
              </p>
            </div>
            <p className="col-span-1 md:col-span-3 order-last md:order-none">
              {parse(item.content.html, options)}
            </p>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default withTrans(legalDisclaimer)
